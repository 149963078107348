<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-table
        ref="substanceTable"
        title="위험물 및 지정수량"
        tableId="substanceTable"
        :columns="grid.columns"
        :data="grid.data"
        :columnSetting="false"
        selection="multiple"
        rowKey="key"
        :filtering="false"
        :usePaging="false"
        :hideBottom="true"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addDangerPermit" />
            <c-btn label="삭제" v-if="editable" icon="remove" />
            <c-btn label="저장" v-if="editable" icon="save" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
// import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-danger',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantCd: '',
        plantName: '',
        materialCd: '',
        materialName: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '유별',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '성질',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '품명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '지정수량',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        width: '70%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.grid.data = [
        {
          col1: '제1류',
          col2: '산화성 고체',
          col3: '1. 아염소산염류',
          col4: '50kg',
        },
        {
          col1: '제1류',
          col2: '산화성 고체',
          col3: '2. 염소산염류',
          col4: '50kg',
        },
      ]
    },
    addDangerPermit() {
      this.popupOptions.title = '위험물 상세';
      this.popupOptions.target = () => import(`${'@/pages/chm/base/dangerItemPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDangerPopup;
    },
    closeDangerPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { col3: item.col3 }) === -1) {
            this.grid.data.push(item);
          }
        })
      }
    },
  }
};
</script>